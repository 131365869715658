import { post } from '../base';

const basePath = () => (
  [
    '/smart_planner/search-opportunities',
  ].join('/')
);

// NOTE: duration must be provided with minutes unit
export const search = ({ userIds, ...params }) => {
  console.log('SEARCH OPPORTUNITY', params);

  return post(`${basePath()}.json`, {
    opportunity: {
      ...params,
      user_ids: userIds,
    },
  });
};

export default {
  search,
};

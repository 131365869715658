export const itemMatchSearchTerm = ({ item, filterKeys, term }) => {
  let found = false;

  filterKeys.forEach((key) => {
    const itemValue = (item[key] || '').toLowerCase();
    const searchResult = itemValue.search(term);

    if (searchResult !== -1) { found = true; }
  });

  return found;
};

export default {
  itemMatchSearchTerm,
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

import sleep from '../../../utils/sleep';
import handleError from '../../../utils/error-handler';
import api from '../../../api/financialAnalysis/classification/categories';
import styles from './InlineCategorization.module.scss';
import AdvancedModal from './InlineCategorization/AdvancedCategorizationModal';
import SelectLabelInput from './InlineCategorization/SelectLabelInput';
import EditLabel from './InlineCategorization/EditLabel';
import {
  createLabel,
  totalRepartition,
  categorizeLabel as defaultCategorizeLabel,
  categorizeLabels as defaultCategorizeLabels,
} from './InlineCategorization/utils';
import { makeUnclassifiedLabel, unclassifiedId } from '../../OperationsPage/commons';
import { success } from '../../../utils/notifier';

const labelsCache = {};

const InlineCategorization = ({
  operationId,
  operation, // NOTE: operation could be blank
  values, // Aka labels
  category,
  onChanging,
  onChange,
  className,
  operationHeadline,
  categorizeLabelPromise,
  categorizeLabelsPromise,
}) => {
  const categorizeLabel = categorizeLabelPromise || defaultCategorizeLabel;
  const categorizeLabels = categorizeLabelsPromise || defaultCategorizeLabels;

  const valuesRepartition = totalRepartition(values);
  // const unclassifiedRepartition = (100 - valuesRepartition) / 100.0;
  // const initialValues = valuesRepartition === 100
  //   ? values
  //   : [makeUnclassifiedLabel({ repartition: unclassifiedRepartition }), ...values];
  const initialValues = [...values];

  // State primitives
  const [isEditing, setIsEditing] = useState(false);
  const [isAdvancedModalShown, showAdvancedModal] = useState(false);
  const [labels, setLabels] = useState([]);
  const currentLabels = initialValues;
  const categoryId = category.category_id || category.categoryId;

  // Categorize multiple labels per category
  // Used for repartited categorization
  const bulkCategorize = (newLabels) => {
    // Before real change
    onChanging({ operationId, categoryId, labels: newLabels });

    categorizeLabels({
      operationId,
      labels: newLabels,
      categoryId,
    }).then((response) => response.json())
      .then((response) => {
        success({ message: response.message });

        onChange({
          response,
          operationId,
          categoryId,
          labels: newLabels,
        });
      })
      .catch(handleError);
  };

  // Categorize a single label per category
  const categorize = (label) => {
    const labelId = label ? label.label_id : null;
    const repartition = label ? label.repartition : 1;
    const newLabels = _.compact([label]);

    // Before real change
    // NOTE: operation coulb be undefined
    onChanging({ operationId: operation?.id, categoryId, labels: newLabels });

    // Api call
    categorizeLabel({
      operationId: operation?.id,
      categoryLabelId: labelId,
      categoryId,
      repartition,
    }).then((response) => response.json())
      .then((response) => {
        success({ message: response.message });

        onChange({
          response,
          operationId,
          categoryId,
          labels: newLabels,
        });
      })
      .catch(handleError);
  };

  const loadLabels = (force = false) => {
    const cachedLabels = labelsCache[categoryId];

    if (cachedLabels && labels.length > 0
      && cachedLabels.length === labels.length
      && !force) { return; }

    if (cachedLabels && !force) {
      setLabels(labelsCache[categoryId]);
      return;
    }

    api.categoryLabels({ categoryId })
      .then((response) => response.json())
      .then((data) => {
        labelsCache[categoryId] = data;
        setLabels(data);
      })
      .catch(handleError);
  };

  const handleAdvancedClick = () => {
    setIsEditing(false);
    loadLabels();
    showAdvancedModal(true);
  };

  const handleEditClick = () => {
    if (currentLabels.length > 1) {
      handleAdvancedClick();
      return;
    }

    loadLabels();
    setIsEditing(true);
  };

  const handleRemoveClick = () => {
    setIsEditing(false);
    categorize(null);
  };

  // Create a remote label when inline created
  const createRemoteLabel = (label) => {
    const repartition = label.repartition || 1;

    let selectedLabel = {
      ...label,
      repartition,
    };

    if (label.__isNew__) {
      const onSuccess = () => {
        // Wait for the projections and then categorize
        sleep(500).then(() => { loadLabels(true); });
      };

      selectedLabel = createLabel({
        name: label.value,
        categoryId,
        onSuccess,
      });
    }

    return Promise.resolve({
      ...selectedLabel,
      repartition,
    });
  };

  const handleSingleLabelSubmit = (label) => {
    setIsEditing(false);
    createRemoteLabel(label).then(categorize);
  };

  const handleMultipleLabelsSubmit = (entities) => {
    setIsEditing(false);
    const realEntities = entities.filter(({ label_id }) => (label_id !== unclassifiedId));
    Promise.all(realEntities.map(createRemoteLabel)).then(bulkCategorize);

    showAdvancedModal(false);
  };

  const handleLabelBlur = () => {
    setIsEditing(false);
  };

  const renderLabelsSelect = () => (
    <SelectLabelInput
      labels={labels}
      onChange={handleSingleLabelSubmit}
      onBlur={handleLabelBlur}
      categoryLabels={currentLabels}
      menuIsOpen
    />
  );

  const renderEditLabel = () => (
    <EditLabel
      onClick={handleEditClick}
      onRemove={handleRemoveClick}
      onAdvanced={handleAdvancedClick}
      labels={currentLabels}
    />
  );

  const renderAdvancedModal = () => {
    if (!isAdvancedModalShown) { return <div />; }

    return (
      <AdvancedModal
        show={isAdvancedModalShown}
        onHide={() => { showAdvancedModal(false); }}
        subtitle={operationHeadline}
        labels={labels}
        category={category}
        values={currentLabels}
        onSubmit={handleMultipleLabelsSubmit}
      />
    );
  };

  return (
    <div className={classnames(className, 'InlineCategorization', styles.InlineCategorization)}>
      {
        isEditing
          ? renderLabelsSelect()
          : renderEditLabel()
      }

      { renderAdvancedModal() }
    </div>
  );
};

InlineCategorization.propTypes = {
  operationId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  operationHeadline: PropTypes.string,
  operation: PropTypes.shape({}),
  category: PropTypes.shape({}).isRequired,
  values: PropTypes.arrayOf(PropTypes.object),
  onChanging: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  categorizeLabelPromise: PropTypes.func,
  categorizeLabelsPromise: PropTypes.func,
};

InlineCategorization.defaultProps = {
  operation: null,
  values: [],
  onChanging: () => {},
  onChange: () => {},
  className: '',
  operationId: null,
  operationHeadline: '',
  categorizeLabelPromise: null,
  categorizeLabelsPromise: null,
};

export default InlineCategorization;

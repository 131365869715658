import React from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';

import ListingTable from '../../ListingTable';
import { itemMatchSearchTerm } from '../../ListingTable/utils';
import SearchBar from './SearchBar';
import Row from './EventRow';

const headers = [
  'Date',
  'Headline',
  'Trainers',
  'Venue',
  'Go/NoGo Date',
  'Status',
  'Registrations',
  'Sold tickets',
  'Actions',
  // 'Training',
  // 'OLD Status',
];

const sortableColumns = {
  Date: 'starts_on',
  Headline: 'headline',
  Trainers: 'trainers_name',
  Registrations: 'sales_registrations_status',
  Status: 'composite_status.status',
  Venue: 'venue',
  'Sold tickets': 'tickets_sold',
  'Go/NoGo Date': 'confirmation_date',
};

const itemWithVenue = ({ item, venue }) => {
  switch (venue) {
    case 'onsite':
      return (item.venue || '') !== '';
    case 'online':
      return (item.venue || '') === '';
    default:
      return true;
  }
};

const DataTable = ({
  plannedEvents,
  onUpdate,
  onDelete,
  hideSearch,
  initialVenueFilter,
}) => {
  const handleOnSearch = ({ items, filters, filterKeys }) => {
    // const activeItem = (item) => (_.includes(activeOperationIds, item.id));
    const { text, venue } = filters;
    const term = (text || '').toLowerCase();

    if (term.length === 0 && venue === 'all') { return items; }

    return items.filter((item) => (
      itemMatchSearchTerm({ item, filterKeys, term })
        && itemWithVenue({ item, venue })
    ));
  };

  return (
    <ListingTable
      items={plannedEvents}
      filterKeys={[
        'headline', 'description', 'trainers_name', 'city', 'venue',
      ]}
      headers={headers}
      sortableColumns={sortableColumns}
      headerRowProps={{ className: 'text-nowrap' }}
      rowProps={{
        onUpdate,
        onDelete,
      }}
      onSearch={handleOnSearch}
      components={{ Row, SearchBar }}
      hideSearch={hideSearch}
      searchBarProps={{ initialVenueFilter }}
      selectable
    />
  );
};

DataTable.propTypes = {
  plannedEvents: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  hideSearch: PropTypes.bool,
  initialVenueFilter: PropTypes.string,
};

DataTable.defaultProps = {
  onUpdate: () => {},
  onDelete: () => {},
  hideSearch: false,
  initialVenueFilter: 'all',
};

export default DataTable;

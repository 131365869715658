import { DateTime } from 'luxon';

export const DEFAULT_LOCALE = 'en-GB';

const n = 'numeric',
      s = 'short',
      l = 'long';

export const DATE_FORMATS = {
  default: DateTime.DATE_MED,
  med: DateTime.DATE_MED,
  medCompact: {
    // year: n,
    month: s,
    day: n,
  },
  short: DateTime.DATE_SHORT,
};

export const TIME_FORMATS = {
  default: DateTime.TIME_24_WITH_SECONDS,
  short: DateTime.TIME_SIMPLE,
};

export const DATETIME_FORMATS = {
  default: DateTime.DATETIME_MED,
  med: DateTime.DATETIME_MED,
  medCompact: {
    // year: n,
    month: s,
    day: n,
    hour: n,
    minute: n,
  },
  short: DateTime.DATETIME_SHORT,
  full: DateTime.DATETIME_FULL,
  huge: DateTime.DATETIME_HUGE,
};

export const formatLuxonDateTime = (dt, { format = 'default', locale = DEFAULT_LOCALE }) => {
  const luxonFormat = DATETIME_FORMATS[format];
  return dt.setLocale(locale).toLocaleString(luxonFormat);
};

export const formatLuxonTime = (dt, { format = 'default', locale = DEFAULT_LOCALE }) => {
  const luxonFormat = TIME_FORMATS[format];
  return dt.setLocale(locale).toLocaleString(luxonFormat);
};

export const formatLuxonDate = (dt, { format = 'default', locale = DEFAULT_LOCALE }) => {
  const luxonFormat = DATE_FORMATS[format];
  return dt.setLocale(locale).toLocaleString(luxonFormat);
};

export const formatIsoDateTime = (iso, options = {}) => {
  const dt = DateTime.fromISO(iso);
  return formatLuxonDateTime(dt, options);
};

export const formatIsoTime = (iso, options = {}) => {
  const dt = DateTime.fromISO(iso);
  return formatLuxonTime(dt, options);
};

export const formatIsoDate = (iso, options = {}) => {
  const dt = DateTime.fromISO(iso);
  return formatLuxonDate(dt, options);
};

/**
 * Translates seconds into human readable format of seconds, minutes, hours, days, and years
 *
 * @param  {number} seconds The number of seconds to be processed
 * @return {string}         The phrase describing the amount of time
 */
export const secondsToHumanLabel = (seconds) => {
  const levels = [
    [Math.floor(seconds / 31536000), 'years'],
    [Math.floor((seconds % 31536000) / 86400), 'days'],
    [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
    [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes'],
    [(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds'],
  ];

  let returntext = '';

  for (let i = 0, max = levels.length; i < max; i += 1) {
    if (levels[i][0] !== 0) {
      returntext += ` ${levels[i][0]} ${(levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length - 1) : levels[i][1])}`;
    }
  }

  return returntext.trim();
};

export const dateToIso = (date) => (date.toISOString());

export default {
  secondsToHumanLabel,
  formatIsoDateTime,
  formatIsoDate,
  formatIsoTime,
  dateToIso,
};

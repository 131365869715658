import React from 'react';

import ListingTable from '../../ListingTable/Infinite';
import Row from '../../ExpectationsListRow';

const ArchiveDatatable = () => {
  const endpoint = '/forecast/expectations/archive.json';

  return (
    <ListingTable
      initialEndpoint={endpoint}
      components={{ Row }}
      filterKeys={['headline', 'notes']}
    />
  );
};

ArchiveDatatable.propTypes = {
};

ArchiveDatatable.defaultProps = {
};

export default ArchiveDatatable;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

import DefaultSearchBar from './SearchBar';
import Header from './Header';
import Body from './Body';
import BulkToolbarContainer from './BulkToolbarContainer';
import { itemMatchSearchTerm } from './utils';

export const defaultOnSearch = ({ items, filters, filterKeys }) => {
  const { text } = filters;
  const term = (text || '').toLowerCase();

  if (term.length === 0) { return items; }

  return items.filter((item) => (itemMatchSearchTerm({ item, filterKeys, term })));
};

export const defaultOnSort = ({ items, column, direction }) => {
  const keys = _.flatten([column]);
  const directions = new Array(keys.length);
  directions.fill(direction);

  return _.orderBy(items, keys, directions);
};

//
// TODO
//
// 1. convert the class into a pure function using react hooks to avoid
//    strange side effects.
//
// 2. The <Body /> component should be passed optionally as prop (see the
//    infinite scroll usecase)
//
class ListingTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items, // Not sure if this is a good idea. Filter the items on each render
      selectedIds: [],
      filters: {},
      sortColumn: null,
      sortDirection: 'asc',
    };
  }

  // TODO: figure out how to avoid this function...
  //
  // maybe we should not track the filtered items into the state.. but should be
  // generate at runtime, during the render
  UNSAFE_componentWillReceiveProps(nextProps) {
    // const { items } = nextProps;
    const { filters } = this.state;
    // this.setState({ items });

    this.performSearch(filters, nextProps);
  }

  handleSort({ direction, sortKey }) {
    const { onSort } = this.props;
    const { items } = this.state;
    const sortedItems = onSort({ items, column: sortKey, direction });

    this.setState({
      items: sortedItems,
      sortColumn: sortKey,
      sortDirection: direction,
    });
  }

  handleSearch(filters, context = null) {
    this.performSearch(filters, context);
  }

  handleDelete(id) {
    const { items } = this.state;
    // const { items } = this.props; // NO
    const filteredItems = items.filter((item) => (this.getItemId(item) !== id));

    this.setState({
      items: filteredItems,
    });
  }

  getSearchBar() {
    const { components } = this.props;

    return components.SearchBar || DefaultSearchBar;
  }

  getRowElement() {
    const { components, rowComponent, row_component } = this.props;

    if (components.Row) { return components.Row; }

    // DEPRECATED: do not use the rowComponent
    return require(`../${rowComponent || row_component}`).default;
  }

  getItemId(item) {
    const { idColumn } = this.props;

    return item[idColumn] || item.id || item._id;
  }

  selectedItems() {
    const { items, selectedIds } = this.props;

    return items.filter((item) => (
      _.includes(selectedIds, this.getItemId(item))
    ));
  }

  itemIds() {
    const { items } = this.state;

    return items.map((item) => (this.getItemId(item)));
  }

  completelySelected() {
    const { selectedIds } = this.state;
    const itemIds = this.itemIds();

    return selectedIds.length === itemIds.length;
  }

  toggleItem(item) {
    const { selectedIds } = this.state;

    if (_.includes(selectedIds, item)) {
      this.setState({
        selectedIds: selectedIds.filter((el) => (el !== item)),
      });
    } else {
      this.setState({
        selectedIds: [...selectedIds, item],
      });
    }
  }

  performSearch(filters, context = null) {
    // Filter
    const {
      items, filterKeys, onSearch, onSort,
    } = context || this.props;

    const filteredItems = onSearch({
      items,
      filters,
      filterKeys,
    });

    // And sort the result
    const { sortColumn, sortDirection } = this.state;
    const sortedItems = onSort({
      items: filteredItems,
      column: sortColumn,
      direction: sortDirection,
    });

    this.setState({
      filters: { ...filters },
      items: sortedItems,
    });
  }

  toggleAll() {
    if (this.completelySelected()) {
      this.setState({ selectedIds: [] });
    } else {
      this.setState({ selectedIds: this.itemIds() });
    }
  }

  renderTableHeader() {
    const { headers } = this.props;

    if (headers.length === 0) { return null; }

    const {
      headerRowProps,
      headerColsProps,
      selectable,
      stickyHeader,
      sortableColumns,
    } = this.props;

    return (
      <Header
        rowProps={headerRowProps}
        colsProps={headerColsProps}
        columns={headers}
        selectable={selectable}
        onSelection={() => { this.toggleAll(); }}
        selected={this.completelySelected()}
        stickyHeader={stickyHeader}
        onSort={(args) => { this.handleSort(args); }}
        sortableColumns={sortableColumns}
      />
    );
  }

  renderSearchBar() {
    const { hideSearch, searchBarProps, items } = this.props;

    if (hideSearch) { return null; }

    const SearchBar = this.getSearchBar();

    return (
      <SearchBar
        {...searchBarProps}
        items={items}
        onFilter={(args) => { this.handleSearch(args); }}
      />
    );
  }

  render() {
    const rows = [];
    const { items, selectedIds } = this.state;

    const {
      tableClassName,
      wrapperProps,
      responsive,
      rowProps,
      selectable,
      stickyHeader,
      stickyColumn,
      wrapperClassName,
      wrapperStyles,
      components,
      infinite,
      infiniteProps,
    } = this.props;

    const { BulkToolbar } = components;
    const RowElement = this.getRowElement();

    if ((items || []).length > 0) {
      items.forEach((item) => {
        const id = this.getItemId(item);
        const selected = _.includes(selectedIds, id);

        rows.push(
          <RowElement
            {...rowProps}
            {...item}
            onSelection={(el) => { this.toggleItem(el); }}
            selectable={selectable}
            selected={selected}
            items={items}
            item={item}
            key={id}
            id={id}
            onRowDelete={(ref) => { this.handleDelete(ref); }}
          />,
        );
      });
    }

    const tableClassNames = classnames(
      'table',
      'table-hover',
      tableClassName, {
        'table--stickyHeader': stickyHeader,
        'table--stickyColumn': stickyColumn,
      },
    );

    const wrapperClassNames = classnames(
      'ListingTable__wrap',
      wrapperClassName,
      { 'table-responsive': responsive },
    );

    return (
      <div {...wrapperProps}>
        {this.renderSearchBar()}

        <BulkToolbarContainer
          {...rowProps}
          selectable={selectable}
          selectedItems={this.selectedItems()}
          totalItems={items.length}
          components={{ BulkToolbar }}
        />

        <div className={wrapperClassNames} style={wrapperStyles}>
          <table className={tableClassNames}>
            {this.renderTableHeader()}

            <Body infinite={infinite} infiniteProps={infiniteProps}>
              {rows}
            </Body>
          </table>
        </div>
      </div>
    );
  }
}

ListingTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filterKeys: PropTypes.arrayOf(PropTypes.string),
  hideSearch: PropTypes.bool,
  tableClassName: PropTypes.string,
  headers: PropTypes.array,
  headerColsProps: PropTypes.array,
  headerRowProps: PropTypes.shape({}),
  wrapperProps: PropTypes.shape({}),
  responsive: PropTypes.bool,
  idColumn: PropTypes.string,
  rowComponent: PropTypes.string,
  row_component: PropTypes.string,
  rowProps: PropTypes.shape({}),
  selectable: PropTypes.bool,
  onSearch: PropTypes.func,
  onSort: PropTypes.func,
  components: PropTypes.shape({}),
  stickyHeader: PropTypes.bool,
  stickyColumn: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  sortableColumns: PropTypes.shape({}),
  wrapperStyles: PropTypes.shape({}),
  infinite: PropTypes.bool,
  infiniteProps: PropTypes.shape({}),
  searchBarProps: PropTypes.shape({})
};

ListingTable.defaultProps = {
  filterKeys: [],
  hideSearch: false,
  tableClassName: '',
  headers: [],
  wrapperProps: { className: 'default-table-list' },
  responsive: false,
  headerColsProps: [],
  headerRowProps: {},
  idColumn: '_id',
  rowProps: {},
  selectable: false,
  onSearch: defaultOnSearch,
  onSort: defaultOnSort,
  components: {},
  stickyHeader: false,
  stickyColumn: false,
  wrapperClassName: null,
  wrapperStyles: {},
  sortableColumns: {},
  infinite: false,
  infiniteProps: {},
  searchBarProps: {},
};

export default ListingTable;

import { httpDelete, post, patch } from '../base';

export const completePlannedEvent = ({ plannedEventId, notes = '' }) => (
  patch(`/trainings/planned_events/${plannedEventId}/complete.json`, {
    planned_event: { notes },
  })
);

export const confirmPlannedEvent = ({ plannedEventId, notes = '' }) => (
  patch(`/trainings/planned_events/${plannedEventId}/confirm.json`, {
    planned_event: { notes },
  })
);

export const enableCertification = ({ plannedEventId }) => (
  patch(`/trainings/planned_events/${plannedEventId}/enable-certification.json`)
);

export const cancelPlannedEvent = ({ plannedEventId, notes = '' }) => (
  httpDelete(`/trainings/planned_events/${plannedEventId}.json`, {
    planned_event: { notes },
  })
);

export const retirePlannedEvent = ({ plannedEventId, notes = '' }) => (
  httpDelete(`/trainings/planned_events/${plannedEventId}/retire.json`, {
    planned_event: { notes },
  })
);

export const updateTrainers = ({ plannedEventId, trainers }) => (
  patch(`/trainings/planned_events/${plannedEventId}/update_trainers.json`, {
    planned_event: { trainers },
  })
);

export const updateConfirmationDate = ({ plannedEventId, confirmationDate }) => {
  confirmationDate.setHours(12); // Workaround to avoid timezone troubles..
  const date = confirmationDate.toISOString().split('T')[0];

  return patch(`/trainings/planned_events/${plannedEventId}/update_confirmation_date.json`, {
    planned_event: { confirmation_date: date },
  });
};

export const updateMinimumAttendance = ({ plannedEventId, minimumAttendance }) => (
  patch(`/trainings/planned_events/${plannedEventId}/update_minimum_attendance.json`, {
    planned_event: { minimum_attendance: minimumAttendance },
  })
);

export const updateLanguage = ({ plannedEventId, language }) => (
  patch(`/trainings/planned_events/${plannedEventId}/update_language.json`, {
    planned_event: { language },
  })
);

export const synchronizePlannedEvent = ({ plannedEventId }) => (
  patch(`/trainings/planned_events/${plannedEventId}/synchronize.json`)
);

export const openPlannedEventRegistrations = ({ plannedEventId }) => (
  post(`/trainings/planned_events/${plannedEventId}/registrations.json`)
);

export const closePlannedEventRegistrations = ({ plannedEventId }) => (
  httpDelete(`/trainings/planned_events/${plannedEventId}/registrations.json`)
);

export const convertIntoOpenEvent = ({ plannedEventId }) => (
  patch(`/trainings/planned_events/${plannedEventId}/convert-into-open-event.json`)
);

export const convertIntoTraining = ({ plannedEventId }) => (
  patch(`/trainings/planned_events/${plannedEventId}/convert-into-training.json`)
);

export default {
  cancelPlannedEvent,
  confirmPlannedEvent,
  completePlannedEvent,
  updateTrainers,
  synchronizePlannedEvent,
  openPlannedEventRegistrations,
  closePlannedEventRegistrations,
  convertIntoOpenEvent,
  convertIntoTraining,
  retirePlannedEvent,
  updateConfirmationDate,
  updateMinimumAttendance,
  updateLanguage,
};

import React from 'react';
// import PropTypes from 'prop-types';
// import classnames from 'classnames';

import ListingTable from '../../ListingTable/Infinite';
import Row from '../../ExpectationsCalendarRow';

const CalendarDatatable = () => {
  const endpoint = '/forecast/expectations/calendar.json';

  return (
    <ListingTable
      initialEndpoint={endpoint}
      components={{ Row }}
      filterKeys={['headline', 'notes']}
    />
  );
};

CalendarDatatable.propTypes = {
};

CalendarDatatable.defaultProps = {
};

export default CalendarDatatable;

import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Money from '../../utils/money';
import { colorize } from './utils';

const DetailedAmountCaption = ({ expectation }) => {
  if (!expectation) { return null; }

  const {
    expected_net_amount,
    expected_amount_vat,
    currency,
    withholding_tax,
    other_withholding_tax,
    direction,
  } = expectation;

  const vatCents = (expected_net_amount?.cents || 0) * (expected_amount_vat || 0) / 100.0;
  const moneyVat = Money({ cents: vatCents, currency_iso: currency });
  const totalWithholdingTaxCents = (withholding_tax?.cents || 0) + (other_withholding_tax?.cents || 0);
  const moneyTotalWithholdingTax = Money({ cents: totalWithholdingTaxCents, currency_iso: currency });
  const moneyToBePaid = Money({ cents: expected_net_amount?.cents - totalWithholdingTaxCents, currency_iso: currency })
  const hasVat = expected_amount_vat > 0;
  const hasTaxes = totalWithholdingTaxCents > 0;

  return (
    <div className="text-nowrap">
      <strong>
        { direction === 'out' ? '-' : '+' }
        {Money(expected_net_amount || {}).toFormat()}
      </strong>


      <div className="small d-inline-block" style={{ position: 'relative', top: -1 }}>
        {
          hasVat && (
            <>
              &nbsp;
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip>
                    {moneyVat.toFormat()}
                  </Tooltip>
                )}
              >
                <span className="badge badge-warning">+IVA</span>
              </OverlayTrigger>
            </>
          )
        }

        {
          hasTaxes && (
            <>
              &nbsp;
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip>
                    {moneyTotalWithholdingTax.toFormat()}
                  </Tooltip>
                )}
              >
                <span className="badge badge-secondary">-RIT</span>
              </OverlayTrigger>
            </>
          )
        }
      </div>

      {
        direction === 'out' && (
          <div className="small">
            Expected:
            &nbsp;
            {moneyToBePaid.toFormat()}
          </div>
        )
      }
    </div>
  );
};

const AmountCaption = ({ expectation }) => {
  if (!expectation) { return null; }

  const {
    expected_net_amount,
    expected_amount,
    expected_amount_vat,
    currency,
    withholding_tax,
    other_withholding_tax,
    direction,
    sign_adjusted_amount,
  } = expectation;

  const vatCents = (expected_net_amount?.cents || 0) * (expected_amount_vat || 0) / 100.0;
  const moneyVat = Money({ cents: vatCents, currency_iso: currency });
  const totalWithholdingTaxCents = (withholding_tax?.cents || 0) + (other_withholding_tax?.cents || 0);
  const moneyTotalWithholdingTax = Money({ cents: totalWithholdingTaxCents, currency_iso: currency });
  const moneyToBePaid = Money({ cents: expected_amount?.cents - totalWithholdingTaxCents, currency_iso: currency })
  const hasVat = expected_amount_vat > 0;
  const hasTaxes = totalWithholdingTaxCents > 0;

  return (
    <div className="text-nowrap">
      <strong>
        {Money(sign_adjusted_amount).toFormat()}
      </strong>

      {
        direction === 'out' && (
          <div className="small mt-1">
            Expected:
            &nbsp;
            {moneyToBePaid.toFormat()}
          </div>
        )
      }
    </div>
  );
};

const PriceValue = ({ expectation }) => {
  const signAdjustedAmount = expectation.sign_adjusted_amount || expectation.signAdjustedAmount;

  return (
    <div className={colorize(signAdjustedAmount)}>
      <AmountCaption expectation={expectation} />
    </div>
  );
};

export default PriceValue;

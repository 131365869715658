import React from 'react';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import { ThemeProvider } from 'styled-components';
import { Datepicker } from '@datepicker-react/styled';

import Icon from '../../Icon';
import { formatIsoDate } from '../../../utils/datetime';
import TrainersInplaceInput from './TrainersInplaceInput';
import EventStatusBadge from './EventStatusBadge';
import { eventShape } from '../utils';
import VenueIcon from './VenueIcon';
import ConfirmationDateInplaceInput from './ConfirmationDateInplaceInput';
import MinimumAttendanceInplaceInput from './MinimumAttendanceInplaceInput';
import LanguageInplaceInput from './LanguageInplaceInput';

const InfoPage = ({ event }) => {
  const {
    headline,
    description,
    starts_on,
    ends_on,
    venue,
    sales_registrations_status,
    training_status,
    composite_status,
    initial_capacity,
  } = event;

  const { status } = composite_status;

  const startsOnDT = DateTime.fromISO(starts_on);
  const endsOnDT = DateTime.fromISO(ends_on);

  const startsOn = startsOnDT.toJSDate();
  const endsOn = endsOnDT.toJSDate();

  const humanStartsOn = formatIsoDate(starts_on);
  const humanEndsOn = formatIsoDate(ends_on);

  return (
    <div className={classnames('info-page')}>
      <div className="row">
        <div className="col-md-6">
          <h3>{headline}</h3>
          <p>{description}</p>

          <dl>
            <dt>
              <Icon name="chalkboard-teacher" fw />
              &nbsp;
              Trainers
            </dt>
            <dd className="">
              <TrainersInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>

            <dt>
              <Icon name="calendar" family="far" fw />
              &nbsp;
              Dates
            </dt>
            <dd className="">
              { `${humanStartsOn} - ${humanEndsOn}` }
            </dd>

            <dt>
              <Icon name="language" fw />
              &nbsp;
              Language
            </dt>
            <dd className="">
              <LanguageInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>

            <dt>
              <Icon name="calendar-times" fw />
              &nbsp;
              Go/No go date
            </dt>
            <dd className="">
              <ConfirmationDateInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>

            <dt>
              <VenueIcon venue={venue} />
              &nbsp;
              Venue
            </dt>
            <dd className="">
              { venue || 'Online' }
            </dd>

            <dt>
              <Icon name="chair" fw />
              &nbsp;
              Capacity
            </dt>
            <dd className="">
              { `${initial_capacity} seats` }
            </dd>

            <dt>
              <Icon name="users" fw />
              &nbsp;
              Minimum attendance
            </dt>
            <dd className="">
              <MinimumAttendanceInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>
          </dl>
        </div>

        <div className="col-md-6">
          <ThemeProvider
            theme={{
              breakpoints: ['32em', '48em', '64em'],
              reactDatepicker: {
                daySize: [36, 40],
                fontFamily: 'system-ui, -apple-system',
                colors: {
                  accessibility: '#D80249',
                  selectedDay: '#f7518b',
                  selectedDayHover: '#F75D95',
                  primaryColor: '#d8366f',
                  normalDayHover: 'transparent',
                },
              },
            }}
          >
            <div className="float-right">
              <table className="table table-sm text-center">
                <thead>
                  <tr>
                    <th style={{ borderTop: 0 }}>Training</th>
                    <th style={{ borderTop: 0 }}>Registrations</th>
                    <th style={{ borderTop: 0 }}>Status</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <EventStatusBadge
                        title={`Training status ${training_status}`}
                        status={training_status}
                        className="text-uppercase"
                      />
                    </td>

                    <td>
                      <EventStatusBadge
                        title={`Registrations sales ${sales_registrations_status}`}
                        status={sales_registrations_status}
                        className="text-uppercase"
                      />
                    </td>

                    <td>
                      <EventStatusBadge
                        title={composite_status.message}
                        status={status}
                        className="text-uppercase"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>

              <Datepicker
                showResetDates={false}
                showSelectedDates={false}
                showClose={false}
                initialVisibleMonth={startsOn}
                minBookingDate={startsOn}
                maxBookingDate={endsOn}
                numberOfMonths={1}
              />

            </div>
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
};

InfoPage.propTypes = {
  event: eventShape.isRequired,
};

InfoPage.defaultProps = {
};

export default InfoPage;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm } from 'react-hook-form';

import DrawModal from './DrawModal';
import SearchButton from './SearchButton';
import Footprint from '../Footprint';
import StepsList from '../Footprint/StepsList';
import { deserializeSteps } from '../utils';
import DateInput from '../../RHF/DateInput';
import SelectInput from '../../RHF/SelectInput';
import Icon from '../../Icon';

const normalizeParams = ({ recipe, ...params }) => ({
  ...params,
});

const recipeLabel = ({ name, color }) => (
  <div>
    <span
      className="ColorMarker"
      style={{ backgroundColor: color, verticalAlign: 'top' }}
    />
    &nbsp;
    <strong style={{ verticalAlign: 'top' }}>{name}</strong>
  </div>
);

const enhanceRecipe = (recipe) => ({
  ...recipe,
  key: recipe.id,
  value: recipe.id,
  label: recipeLabel(recipe),
});

const enhanceUser = (user) => ({
  ...user,
  key: user.id,
  value: user.id,
  label: user.full_name,
});

const SearchForm = ({
  onSearch,
  onChange,
  recipes,
  users,
  currentUserId,
  isLoading,
  formId,
  onOpportunitiesStepsChange,
  canSearch,
  hideRecipeInput,
  hideDesigner,
  showStepsList,
  showNewOpportunityCTA,
  showEditOpportunityCTA,
  initialRecipe,
  preload,
}) => {
  const selectInputRef = useRef(null);
  const enhancedInitialRecipe = initialRecipe ? enhanceRecipe(initialRecipe) : null;
  const enhancedRecipes = recipes.map(enhanceRecipe);
  const enhancedUsers = users.map(enhanceUser);
  const enhancedInitialUsers = [...enhancedUsers].filter(({ id }) => (id === currentUserId));
  const [currentUsers, setCurrentUsers] = useState(enhancedInitialUsers);
  const [currentRecipe, setCurrentRecipe] = useState(enhancedInitialRecipe);
  const [opportunitySteps, setOpportunitySteps] = useState(enhancedInitialRecipe?.steps || []);
  const [searchFrom, setSearchFrom] = useState(new Date());
  const [searchTo, setSearchTo] = useState(null);
  const [showNewOpportunityModal, setShowNewOpportunityModal] = useState(false);
  const [showEditOpportunityModal, setShowEditOpportunityModal] = useState(false);
  const initialSteps = (currentRecipe || {}).steps;
  const defaultFormValues = {
    users: enhancedInitialUsers,
    recipe: enhancedInitialRecipe,
    from:   new Date(),
  };
  // const deserializedSteps = currentRecipe
  //   ? deserializeSteps(currentRecipe.steps)
  //   : [];
  const deserializedSteps = opportunitySteps;

  const {
    handleSubmit,
    control,
    formState,
    getValues,
  } = useForm({ defaultValues: defaultFormValues });

  useEffect(() => {
    const currentAttributes = getCurrentAttributes();

    onChange(currentAttributes);
  }, [opportunitySteps, searchFrom, searchTo, currentUsers]);

  const { errors } = formState;

  const getCurrentAttributes = () => {
    const { users, ...values } = getValues();

    return normalizeParams({
      ...values,
      userIds: users.map(({ id }) => (id)),
      steps: opportunitySteps,
    });
  };

  const onSubmit = ({ users, ...params }) => {
    const normalizedParams = normalizeParams({
      ...params,
      steps: opportunitySteps,
      userIds: users.map(({ id }) => (id)),
    });

    onSearch(normalizedParams);
  };

  const handleRecipeChange = (value) => {
    setCurrentRecipe(() => (value));

    if (hideDesigner && value) {
      setOpportunitySteps(deserializeSteps(value.steps));
    }
  };

  const handleOpportunityChange = (steps) => {
    setOpportunitySteps(() => ([...steps]));
  };

  const handleNewOpportunityDraw = (steps) => {
    setOpportunitySteps(() => ([...steps]));
    selectInputRef.current.select.clearValue();
  };

  const handleEditOpportunityDraw = (steps) => {
    setOpportunitySteps(() => ([...steps]));
    selectInputRef.current.select.clearValue();
  };

  // Did mount
  useEffect(() => {
    if (preload) {
      const attrs = getCurrentAttributes();

      onSearch(attrs);
    }
  }, []);

  // const handleCreateRecipe = (params) => (
  //   createRecipe(params).then((recipe) => {
  //     const enhancedRecipe = enhanceRecipe(recipe);

  //     setValue('recipe', enhancedRecipe);
  //     handleRecipeChange(enhancedRecipe);

  //     return enhancedRecipe;
  //   })
  // );

  return (
    <div className="SearchOpportunitiesPage__form">
      <Form onSubmit={handleSubmit(onSubmit)} id={formId}>
        {
          !hideRecipeInput && (
            <div className="row">
              <div className="col-xl-6">
                <SelectInput
                  ref={selectInputRef}
                  label={false}
                  placeholder="Select an opportunity recipe"
                  name="recipe"
                  errors={errors}
                  control={control}
                  options={enhancedRecipes}
                  className="Select-lg"
                  valueKey="id"
                  onChange={handleRecipeChange}
                  isClearable
                />
              </div>
              <div className="col-xl-6 text-right">
                {
                  showNewOpportunityCTA && (
                    <>
                      <Button
                        size="lg"
                        className="mr-1"
                        variant="light"
                        onClick={() => { setShowNewOpportunityModal(true); }}
                      >
                        <Icon name="pencil-ruler" fw />
                        &nbsp;
                        Draw opportunity
                      </Button>
                      <DrawModal
                        title="Draw new opportunity"
                        show={showNewOpportunityModal}
                        onConfirm={(steps) => { handleNewOpportunityDraw(steps); }}
                        onHide={() => { setShowNewOpportunityModal(false); }}
                      />
                    </>
                  )
                }
                {
                  showEditOpportunityCTA && (
                    <>
                      <Button
                        size="lg"
                        className="mr-1"
                        variant="info"
                        onClick={() => { setShowEditOpportunityModal(true); }}
                        disabled={opportunitySteps.length === 0}
                      >
                        <Icon name="pencil-alt" fw />
                        &nbsp;
                        Edit opportunity
                      </Button>
                      <DrawModal
                        title="Edit opportunity"
                        initialSteps={opportunitySteps}
                        show={showEditOpportunityModal}
                        onConfirm={(steps) => { handleEditOpportunityDraw(steps); }}
                        onHide={() => { setShowEditOpportunityModal(false); }}
                      />
                    </>
                  )
                }
                <SearchButton
                  formId={formId}
                  isLoading={isLoading}
                  size="lg"
                  caption="Search FAD"
                  disabled={!canSearch}
                />
              </div>
            </div>
          )
        }

        {
          showStepsList && (
            <div className="mb-3">
              <StepsList
                steps={deserializedSteps}
                layout="horizontal"
                readOnly
              />
            </div>
          )
        }

        {
          !hideDesigner && (
            <div className="row">
              <div className="col-12">
                <Footprint
                  height={350}
                  onChange={(steps) => { handleOpportunityChange(steps); }}
                  initialSteps={initialSteps}
                  className="my-4"
                />
              </div>
            </div>
          )
        }

        <div className="row">
          <div className="col-md-6 col-lg-4 col-xl-6">
            <SelectInput
              label="Users"
              name="users"
              options={enhancedUsers}
              errors={errors}
              control={control}
              onChange={(selection) => { setCurrentUsers(selection); }}
              size="lg"
              isMulti
              required
            />
          </div>

          <div className="col-md-6 col-lg-4 col-xl-3">
            <DateInput
              label="From date"
              placeholder="From date"
              name="from"
              errors={errors}
              control={control}
              onChange={(from) => { setSearchFrom(from); }}
              required
            />
          </div>

          <div className="col-md-6 col-lg-4 col-xl-3">
            <DateInput
              label="To date"
              placeholder="To date"
              name="to"
              errors={errors}
              control={control}
              onChange={(to) => { setSearchTo(to); }}
            />
          </div>
        </div>

        {
          !hideDesigner && (
            <>
              <hr />

              <div className="row">
                <div className="col text-right">
                  <SearchButton
                    formId={formId}
                    isLoading={isLoading}
                    size="lg"
                    caption="Search slots"
                    disabled={!canSearch}
                  />
                </div>
              </div>
            </>
          )
        }
      </Form>
    </div>
  );
};

SearchForm.defaultProps = {
  canSearch: false,
  isLoading: false,
  onChange: () => {},
  onSearch: () => {},
};

SearchForm.propTypes = {
  canSearch: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
};

export default SearchForm;

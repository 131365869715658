import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import OriginalDateInput from '../inputs/DateInput';

// const DateInput = React.forwardRef(({
//   label,
//   errors,
//   name,
//   control,
//   required,
//   ...props
// }, ref) => (

const DateInput = ({
  label,
  errors,
  name,
  control,
  required,
  onChange,
  ...props
}) => (
  <Form.Group controlId={`form-grid-${name}`}>
    {
      label && (
        <Form.Label>
          {label}
          &nbsp;
          {required && (<abbr title="required">*</abbr>)}
        </Form.Label>
      )
    }
    <Controller
      render={({ field }) => (
        <OriginalDateInput
          {...props}
          {...field}
          onChange={(e) => {
            onChange(e);
            field.onChange(e);
          }}
        />
      )}
      control={control}
      name={name}
      rules={{ required }}
    />
    {errors[name] && (
      <Form.Control.Feedback type="invalid">
        {errors[name].message || 'Required'}
      </Form.Control.Feedback>
    )}
  </Form.Group>
);

DateInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

DateInput.defaultProps = {
  label: 'Input',
  errors: {},
  required: false,
  onChange: () => {},
};

export default DateInput;

import React from 'react';
import PropTypes from 'prop-types';
import StopButton from './expectations/StopRecurringButton';
import ExtendButton from './expectations/ExtendRecurringButton';
import Icon from './Icon';
import { generateUrl } from './expectations/commons';

import PriceValue from './expectations/PriceValue';

const colorizeDeadline = (deadline) => {
  let color = '';

  if (Date.parse(deadline) < Date.now()) {
    color = 'badge badge-warning';
  } else {
    color = 'badge badge-light';
  }

  return color;
};

const iconFor = (expectationType, direction) => {
  let iconString = 'fa fa-lg';
  if (expectationType === 'single-shot' && direction === 'out') iconString += ' fa-arrow-down';
  if (expectationType === 'single-shot' && direction === 'in') iconString += ' fa-arrow-up';
  if (expectationType === 'recurring') iconString += ' fa-redo';

  return iconString;
};

class ExpectationsListRow extends React.Component {
  renderRegisterCompleteCta() {
    const { id, acl, direction } = this.props;
    if (!acl.canRegisterComplete) { return ''; }

    return (
      <a
        href={generateUrl({ id, direction, action: 'register_complete' })}
        className="btn btn-ghost-success btn-sm"
        data-toggle="tooltip"
        data-placement="top"
        title="Mark as fulfilled"
        data-original-title="Mark as fulfilled"
      >
        <Icon name="check" />
      </a>
    );
  }

  renderEditCta() {
    const { id, acl, direction } = this.props;
    if (!acl.canEdit) { return ''; }

    return (
      <a
        href={generateUrl({ id, direction, action: 'edit' })}
        className="btn btn-ghost-info btn-sm"
        data-toggle="tooltip"
        data-placement="top"
        title="Edit expectation"
        data-original-title="Edit expectation"
      >
        <Icon name="edit-alt" />
      </a>
    );
  }

  renderDeleteCta() {
    const { id, acl, direction } = this.props;
    if (!acl.canDelete) { return ''; }

    return (
      <a
        href={generateUrl({ id, direction })}
        className="btn btn-ghost-dark btn-sm"
        data-toggle="tooltip"
        data-placement="top"
        data-confirm="Are you sure?"
        data-method="delete"
        title="Delete expectation"
        data-original-title="Delete expectation"
      >
        <Icon name="trash" />
      </a>
    );
  }

  renderDeleteRecurringCta() {
    const { id, acl } = this.props;
    if (!acl.canDeleteRecurring) { return ''; }

    return (
      <a
        href={generateUrl({ id, recurring: true })}
        className="btn btn-ghost-dark btn-sm"
        data-toggle="tooltip"
        data-placement="top"
        data-confirm="Are you sure?"
        data-method="delete"
        title="Delete recurring expectation"
        data-original-title="Delete recurring expectation"
      >
        <Icon name="trash" />
      </a>
    );
  }

  renderRenewCta() {
    const { id, acl, direction } = this.props;
    if (!acl.canRenew) { return ''; }

    return (
      <a
        href={generateUrl({ id, direction, action: 'renew' })}
        className="btn btn-ghost-warning btn-sm"
        data-toggle="tooltip"
        data-placement="top"
        title="Renew fulfilled expectation"
        data-original-title="Renew fulfilled expectation"
      >
        <Icon name="redo" />
      </a>
    );
  }

  renderStopReurringCta() {
    const {
      id, direction, acl, headline, expectedAmount,
    } = this.props;

    return (
      <StopButton
        id={id}
        direction={direction}
        acl={acl}
        headline={headline}
        expectedAmount={expectedAmount}
        className="btn btn-ghost-danger btn-sm"
        label=""
      />
    );
  }

  renderExtendReurringCta() {
    const {
      id, direction, acl, headline, expectedAmount,
    } = this.props;

    return (
      <ExtendButton
        id={id}
        direction={direction}
        acl={acl}
        headline={headline}
        expectedAmount={expectedAmount}
        className="btn btn-ghost-success btn-sm"
        label=""
      />
    );
  }

  render() {
    const {
      id,
      headline,
      direction,
      item,
    } = this.props;

    // const signAdjustedAmount = item.sign_adjusted_amount || item.signAdjustedAmount;
    const expectedOnDate = item.expected_on_date || item.expectedOnDate;
    const expectationType = item.expectation_type || item.expectationType;

    return (
      <tr key={id} className="row">
        <td className="text-right col-lg-3">
          <PriceValue expectation={item} />
        </td>

        <td className="col-lg-1 text-center">
          <i className={iconFor(expectationType, direction)} />
        </td>

        <td className="col-lg-3 ">
          <div className="text-truncate" style={{ maxWidth: 150 }}>
            <a href={generateUrl({ id, direction })}>{headline}</a>
          </div>
        </td>

        <td className="col-lg-2">
          <h5>
            <span className={colorizeDeadline(expectedOnDate)}>
              {expectedOnDate}
            </span>
          </h5>
        </td>

        <td className="col-lg-3 text-center">
          {this.renderExtendReurringCta()}
          {this.renderStopReurringCta()}
          {this.renderRegisterCompleteCta()}
          {this.renderEditCta()}
          {this.renderRenewCta()}
          {this.renderDeleteCta()}
          {this.renderDeleteRecurringCta()}
        </td>
      </tr>
    );
  }
}

ExpectationsListRow.propTypes = {
  id: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  acl: PropTypes.shape({}),
  item: PropTypes.shape({}).isRequired,
};

ExpectationsListRow.defaultProps = {
  acl: {},
};

export default ExpectationsListRow;

import Flatpickr from 'react-flatpickr';
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { DATEPICKER_FORMAT } from '../../utils/locale';

import 'flatpickr/dist/themes/airbnb.css';
//
// const DateInput = React.forwardRef(({
//   label,
//   errors,
//   name,
//   control,
//   required,
//   ...props
// }, ref) => (

const DateInput = React.forwardRef(({
  label,
  className,
  options,
  onChange,
  ...otherOptions
}, ref) => (
  <div className="form-group date-input">
    {
      label.length > 0
        ? <label>{label}</label>
        : null
    }
    <div className="input-group date-input">
      <Flatpickr
        ref={ref}
        options={options}
        className={className}
        onChange={([date]) => { onChange(date); }}
        {...otherOptions}
      />

      <div className="input-group-append">
        <div className="input-group-text">
          <Icon name="calendar" fw />
        </div>
      </div>
    </div>
  </div>
));

DateInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.shape({}),
  onChange: PropTypes.func,
};

DateInput.defaultProps = {
  label: '',
  className: 'form-control form-control-lg',
  options: { dateFormat: DATEPICKER_FORMAT },
  onChange: () => {},
};

export default DateInput;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import ConfirmModal from './ConfirmModal';

const ConfirmModalButton = ({
  onConfirm,
  size,
  className,
  variant,
  buttonCaption,
  title,
  description,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const hideConfirmModal = () => { setShowConfirmModal(false); };

  const handleButtonClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmation = () => {
    onConfirm();
  };

  return (
    <>
      <ConfirmModal
        show={showConfirmModal}
        onHide={hideConfirmModal}
        onConfirm={handleConfirmation}
        title={title}
        description={description}
        variant={variant}
      />

      <Button
        size={size}
        variant={variant}
        onClick={handleButtonClick}
        className={className}
        title={title}
      >
        {buttonCaption}
      </Button>
    </>
  );
};

ConfirmModalButton.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  buttonCaption: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ConfirmModalButton.defaultProps = {
  size: 'md',
  className: '',
  buttonCaption: 'Confirm',
  variant: 'success',
};

export default ConfirmModalButton;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tabs, Tab } from 'react-bootstrap';
import { debounce } from 'lodash';

import InfoPage from './InfoPage';
import SalesPage from './SalesPage';
import TicketsPage from './TicketsPage';
import CertificationPage from './CertificationPage';
import SettingsPage from './SettingsPage';
import Icon from '../../Icon';
import WithPlannedEvent from './WithPlannedEvent';
import EventBus from '../../../packs/event_bus';
import { reload } from '../../../utils/router';
import sleep from '../../../utils/sleep';
import { eventShape } from '../utils';

const TabTitle = ({ title, icon, iconFamily = 'fas' }) => (
  <span>
    <Icon name={icon} family={iconFamily} fw />
    &nbsp;
    {title}
  </span>
);

const events = [
  'Trainings::PublicEdition::MainInfoChanged',
  'Trainings::PublicEdition::ScheduleChanged',
  'Trainings::PublicEdition::StatusChangedFromExternalSystem',
  'Trainings::PublicEdition::PublicTrainingEditionConfirmed',
  'Trainings::PublicEdition::PublicTrainingEditionCancelled',
  'Trainings::PublicEdition::PublicTrainingEditionMarkedComplete',
  'Trainings::PublicEdition::PublicTrainingEditionDataEnriched',
  'Trainings::PublicEdition::PublicTrainingEditionCertificateEmailTemplateEnriched',
  'Trainings::Certifications::PublicEditionCertificationEnabled',
];

const EventPage = ({
  plannedEvent,
  tickets,
  certification,
  certificatesHost,
  classificationCategories,
  classificationRules,
  loading,
}) => {
  const currUrl = location.href.toString()
  let urlParams = new URLSearchParams(currUrl.split('?')[1] || '');

  const initialTab = urlParams.get('view') || 'info';
  const [key, setKey] = useState(initialTab);
  const handleTabChange = (newKey) => {
    const newUrl = `${currUrl.split('?')[0]}?view=${newKey}`

    window.history.replaceState({}, '', newUrl);
    setKey(newKey);
  };

  // Did mount
  useEffect(() => {
    let mounted = true;
    let eventBusSubscription;
    const handleEvent = (event) => {
      if (plannedEvent.id === event.public_training_edition_id) {
        sleep(1000).then(() => { reload(); });
      }
    };

    if (mounted) {
      eventBusSubscription = EventBus.subscribe(
        events,
        debounce(handleEvent, 250),
        this,
      );
    }

    return () => {
      mounted = false;
      EventBus.unsubscribe(eventBusSubscription);
    };
  }, []);

  return (
    <div className={classnames('event-page')}>
      <Tabs
        activeKey={key}
        id="planned-event"
        onSelect={handleTabChange}
      >
        <Tab
          eventKey="info"
          title={<TabTitle title="Information" icon="info" />}
        >
          <InfoPage event={plannedEvent} />
        </Tab>

        <Tab
          eventKey="sales"
          title={<TabTitle title="Financial analysis" icon="chart-bar" />}
        >
          <SalesPage
            event={plannedEvent}
            tickets={tickets}
          />
        </Tab>

        <Tab
          eventKey="tickets"
          title={<TabTitle title={`Attendees`} icon="user-friends" />}
        >
          <TicketsPage
            event={plannedEvent}
            tickets={tickets}
          />
        </Tab>

        <Tab
          eventKey="certification"
          title={<TabTitle title="Certificates" icon="stamp" />}
        >
          <CertificationPage
            event={plannedEvent}
            certification={certification}
            certificatesHost={certificatesHost}
          />
        </Tab>

        <Tab
          eventKey="settings"
          title={<TabTitle title="Settings" icon="cogs" />}
        >
          <SettingsPage
            event={plannedEvent}
            classificationCategories={classificationCategories}
            classificationRules={classificationRules}
            loading={loading}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

EventPage.propTypes = {
  certificatesHost: PropTypes.string.isRequired,
  plannedEvent: eventShape.isRequired,
  tickets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
  })),
  certification: PropTypes.shape({
    id: PropTypes.string,
  }),
  classificationCategories: PropTypes.arrayOf(PropTypes.shape({})),
  classificationRules: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
};

EventPage.defaultProps = {
  tickets: [],
  certification: null,
  classificationCategories: [],
  classificationRules: [],
  loading: false,
};

export default WithPlannedEvent(EventPage);

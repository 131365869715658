import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import classnames from 'classnames';

import Icon from '../Icon';
import { extractError } from './utils';

const optionLabel = ({ option, iconKey, labelKey }) => {
  if (!option[iconKey]) { return option[labelKey]; }

  return (
    <>
      <Icon name={option[iconKey]} fw />
      &nbsp;
      {option[labelKey]}
    </>
  );
};

const computeOptions = ({
  options,
  labelKey,
  valueKey,
  iconKey,
}) => (
  options.map((option) => {
    const label = optionLabel({ option, labelKey, iconKey });
    const value = option[valueKey] || option.value;

    return {
      key: value,
      ...option,
      label,
      value,
      orig: option,
    };
  })
);

const SelectInput = forwardRef(({
  label,
  type,
  errors,
  name,
  control,
  required,
  className,
  options,
  labelKey,
  valueKey,
  iconKey,
  onChange,
  size,
  ...props
}, ref) => {
  const error = extractError(errors, name);
  const errorMessage = error
    ? error.message || 'Required field'
    : null;
  const hasErrors = !!errorMessage;
  const computedOptions = computeOptions({
    options,
    labelKey,
    iconKey,
    valueKey,
  });

  return (
    <Form.Group
      controlId={`form-grid-${name}`}
      className={classnames({ 'form-group-invalid': hasErrors })}
    >
      {
        label && (
          <Form.Label>
            {label}
            &nbsp;
            {required && (<abbr title="required">*</abbr>)}
          </Form.Label>
        )
      }
      <Controller
        render={({ field }) => (
          <Select
            {...props}
            {...field}
            ref={ref}
            className={classnames('Select', className, { 'is-invalid': hasErrors, 'Select-lg': size === 'lg' })}
            options={computedOptions}
            onChange={(value) => {
              field.onChange(value || null);
              onChange(value || null);
            }}
          />
        )}
        control={control}
        name={name}
        rules={{ required }}
      />
      {
        errorMessage && (
          <Form.Control.Feedback type="invalid">
            {errorMessage}
          </Form.Control.Feedback>
        )
      }
    </Form.Group>
  );
});

SelectInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string, PropTypes.bool,
  ]),
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  errors: PropTypes.shape({}),
  control: PropTypes.shape({}).isRequired,
  required: PropTypes.bool,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  iconKey: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};

SelectInput.defaultProps = {
  className: '',
  label: 'Input',
  type: 'text',
  errors: {},
  required: false,
  valueKey: 'value',
  labelKey: 'label',
  iconKey: 'icon',
  options: [],
  onChange: () => {},
};

export default SelectInput;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const SelectableColumn = ({
  item, className, selectable, selected, onChange, header,
}) => {
  if (!selectable) { return null; }
  const Cell = header ? 'th' : 'td';

  return (
    <Cell className={classnames('listing-table-row__selection', className)}>
      <input
        type="checkbox"
        checked={selected}
        onChange={() => { onChange(item); }}
      />
    </Cell>
  );
};

SelectableColumn.propTypes = {
  item: PropTypes.string.isRequired,
  className: PropTypes.string,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  header: PropTypes.bool,
  onChange: PropTypes.func,
};

SelectableColumn.defaultProps = {
  className: '',
  selectable: false,
  selected: false,
  header: false,
  onChange: () => {},
};

export default SelectableColumn;

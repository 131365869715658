import React from 'react';
import PropTypes from 'prop-types';
import Money from '../../utils/money';
import Cable from '../../channels/FinancialAnalysis';
import { accountTransactions } from '../../api/bankingAccounts';

class ReactiveTotalLiquitity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      values: props.initialValues,
    };
  }

  componentDidMount() {
    this.cableSubscriptionId = Cable.subscribe(
      'account_mirrors_view:updated',
      this.handleAccountUpdate,
      this,
    );
  }

  componentWillUnmount() {
    Cable.unsubscribe(this.cableSubscriptionId);
  }

  handleAccountUpdate({ id }) {
    accountTransactions({ id })
      .then((response) => response.json())
      .then((response) => {
        const balance = response.banking_account.current_balance;
        const { values } = this.state;
        const newValues = {
          ...values,
          [id]: balance,
        };

        this.setState({ values: newValues });
      });
  }

  render() {
    const zeroParams = { cents: 0, currencyIso: 'EUR' };
    const { values } = this.state;
    const total = Object.values(values).reduce((t, value) => (
      t.add(new Money(value || zeroParams))
    ), new Money(zeroParams));

    return (
      <span>
        {total.toFormat()}
      </span>
    );
  }
}

ReactiveTotalLiquitity.propTypes = {
  initialValues: PropTypes.object,
};

ReactiveTotalLiquitity.defaultProps = {
  initialValues: {},
};

export default ReactiveTotalLiquitity;

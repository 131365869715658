import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import SelectableColumn from './SelectableColumn';

const Row = ({
  className, children, selectable, selected, id, onSelection,
}) => (
  <tr className={classnames('listing-table-row', className)}>
    <SelectableColumn
      selectable={selectable}
      selected={selected}
      item={id}
      onChange={(item) => { onSelection(item); }}
    />

    {children}
  </tr>
);

Row.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  onSelection: PropTypes.func,
};

Row.defaultProps = {
  className: '',
  selectable: false,
  selected: false,
  onSelection: () => {},
};

export default Row;
